<template>
  <div class="StageTask_wrapper">
    <!-- 添加阶段任务 -->
    <el-dialog :visible.sync="addTaskDrawer" :show-close="false" center width="700px">
      <el-card class="box_card">
        <div slot="header" class="clearfix">
          <span>添加阶段任务</span>
        </div>
        <el-form :model="editStepForm" label-width="170px">
          <el-form-item label="阶段任务：" required>
            <el-input
              v-model="editStepForm.name"
              placeholder="请输入阶段任务名称"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="任务时间：" required>
            <el-date-picker
              v-model="editStepForm.timeRange"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="奖励总金额：">
            <div>{{totalBonusPool}}</div>
          </el-form-item>
          <el-form-item label="可用金额:">
            <div>{{availableBonusPool}}</div>
          </el-form-item>
          <el-form-item label="该阶段使用金额：" required>
            <el-input v-model.float="editStepForm.staffBonusPool" placeholder="请输入金额" maxlength="8"></el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addTaskDrawer=false">取 消</el-button>
        <el-button type="primary" @click="submitInfo">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导购员任务设置 -->
    <el-dialog :visible.sync="dialogWorkerTask" :show-close="false" center width="1600px">
      <el-card class="box_card">
        <div slot="header" class="clearfix">
          <span>导购员任务设置</span>
        </div>
        <div class="kpi_info_wrap">
          <div class="kpi_info_item">
            <p class="name">浏览</p>
            <el-input v-model.number="staffDetail.viewNum" maxlength="8" placeholder="请输入内容"></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">裂变</p>
            <el-input v-model.number="staffDetail.fissionNum" maxlength="8" placeholder="请输入内容"></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">报名</p>
            <el-input v-model.number="staffDetail.enrollmentNum" maxlength="8" placeholder="请输入内容"></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">售券</p>
            <el-input v-model.number="staffDetail.cardNum" maxlength="8" placeholder="请输入内容"></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">进入直播间</p>
            <el-input v-model.number="staffDetail.liveViewNum" maxlength="8" placeholder="请输入内容"></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">直播下订</p>
            <el-input
              v-model.number="staffDetail.liveOrderCusNum"
              maxlength="8"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">直播自签单</p>
            <el-input
              v-model.number="staffDetail.liveSelfOrderNum"
              maxlength="8"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">直播贡献单</p>
            <el-input
              v-model.number="staffDetail.liveOtherOrderNum"
              maxlength="8"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">直播自签转单</p>
            <el-input
              v-model.number="staffDetail.liveSelfOrderChangeNum"
              maxlength="8"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">直播贡献转单</p>
            <el-input
              v-model.number="staffDetail.liveOtherOrderChangeNum"
              maxlength="8"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">过程自签单</p>
            <el-input
              v-model.number="staffDetail.processSelfOrderNum"
              maxlength="8"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">过程贡献单</p>
            <el-input
              v-model.number="staffDetail.processDevoteOrderNum"
              maxlength="8"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">秒杀</p>
            <el-input v-model.number="staffDetail.seckillUserNum" maxlength="8" placeholder="请输入内容"></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">集赞</p>
            <el-input v-model.number="staffDetail.thumbNum" maxlength="8" placeholder="请输入内容"></el-input>
          </div>
          <div class="kpi_info_item">
            <p class="name">直播预约</p>
            <el-input v-model.number="staffDetail.appointmentNum" maxlength="8" placeholder="请输入内容"></el-input>
          </div>
        </div>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogWorkerTask=false">取 消</el-button>
        <el-button type="primary" @click="SaveOrUpdateStaffStep">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 品牌任务设置 -->
    <el-dialog :visible.sync="dialogBannerTask" :show-close="false" center width="1600px">
      <el-form :model="brandDetail" :rules="rules" ref="ruleForm">
        <el-card class="box_card">
          <div slot="header" class="clearfix">
            <span>品牌任务指标及惩罚金</span>
          </div>
          <div class="kpi_info_wrap">
            <div class="kpi_info_item">
              <p class="name">浏览</p>
              <el-form-item prop="viewNum">
                <el-input v-model.number="brandDetail.viewNum" maxlength="8" placeholder="请输入内容"></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="viewPenalty">
                <el-input
                  v-model.number="brandDetail.viewPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">裂变</p>
              <el-form-item prop="fissionNum">
                <el-input v-model.number="brandDetail.fissionNum" maxlength="8" placeholder="请输入内容"></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="fissionPenalty">
                <el-input
                  v-model.number="brandDetail.fissionPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">报名</p>
              <el-form-item prop="enrollmentNum">
                <el-input
                  v-model.number="brandDetail.enrollmentNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="enrollmentPenalty">
                <el-input
                  v-model.number="brandDetail.enrollmentPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">售券</p>
              <el-form-item prop="cardNum">
                <el-input v-model.number="brandDetail.cardNum" maxlength="8" placeholder="请输入内容"></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="cardPenalty">
                <el-input
                  v-model.number="brandDetail.cardPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">进入直播间</p>
              <el-form-item prop="liveViewNum">
                <el-input
                  v-model.number="brandDetail.liveViewNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="liveViewPenalty">
                <el-input
                  v-model.number="brandDetail.liveViewPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">直播下订</p>
              <el-form-item prop="liveOrderCusNum">
                <el-input
                  v-model.number="brandDetail.liveOrderCusNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="liveOrderCusPenalty">
                <el-input
                  v-model.number="brandDetail.liveOrderCusPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">直播自签单</p>
              <el-form-item prop="liveSelfOrderNum">
                <el-input
                  v-model.number="brandDetail.liveSelfOrderNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="liveSelfOrderPenalty">
                <el-input
                  v-model.number="brandDetail.liveSelfOrderPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">直播贡献单</p>
              <el-form-item prop="liveOtherOrderNum">
                <el-input
                  v-model.number="brandDetail.liveOtherOrderNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="liveOtherOrderPenalty">
                <el-input
                  v-model.number="brandDetail.liveOtherOrderPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">直播自签转单</p>
              <el-form-item prop="liveSelfOrderChangeNum">
                <el-input
                  v-model.number="brandDetail.liveSelfOrderChangeNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="liveSelfOrderChangePenalty">
                <el-input
                  v-model.number="brandDetail.liveSelfOrderChangePenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">直播贡献转单</p>
              <el-form-item prop="liveOtherOrderChangeNum">
                <el-input
                  v-model.number="brandDetail.liveOtherOrderChangeNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="liveOtherOrderChangePenalty">
                <el-input
                  v-model.number="brandDetail.liveOtherOrderChangePenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>

            <div class="kpi_info_item">
              <p class="name">过程自签单</p>
              <el-form-item prop="processSelfOrderNum">
                <el-input
                  v-model.number="brandDetail.processSelfOrderNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="processSelfOrderPenalty">
                <el-input
                  v-model.number="brandDetail.processSelfOrderPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">过程贡献单</p>
              <el-form-item prop="processDevoteOrderNum">
                <el-input
                  v-model.number="brandDetail.processDevoteOrderNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="processDevoteOrderPenalty">
                <el-input
                  v-model.number="brandDetail.processDevoteOrderPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">秒杀</p>
              <el-form-item prop="seckillUserNum">
                <el-input
                  v-model.number="brandDetail.seckillUserNum"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="seckillUserPenalty">
                <el-input
                  v-model.number="brandDetail.seckillUserPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">集赞</p>
              <el-form-item prop="thumbNum">
                <el-input v-model.number="brandDetail.thumbNum" maxlength="8" placeholder="请输入内容"></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="thumbPenalty">
                <el-input
                  v-model.number="brandDetail.thumbPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
            <div class="kpi_info_item">
              <p class="name">直播预约</p>
              <el-form-item prop="appointmentNum">
                <el-input v-model.number="brandDetail.appointmentNum" maxlength="8" placeholder="请输入内容"></el-input>
              </el-form-item>
              <p class="name">惩罚金</p>
              <el-form-item prop="thumbPenalty">
                <el-input
                  v-model.number="brandDetail.appointmentPenalty"
                  maxlength="8"
                  placeholder="请输入内容"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </el-card>
        <div style="margin:0 auto">
          <el-form-item>
            <el-button @click="dialogBannerTask=false">取 消</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
    <!-- 数据统计显示 -->
    <el-dialog :visible.sync="dialogDataTotal" center width="1600px" class="datatotal_dialog">
      <p class="view_title">数据统计</p>
      <div class="select_wrap">
        <!-- 待与后端确认原型 -->
        <el-select v-model="KpiUserInfo.completionStatus" placeholder="完成状态" class="mr10" clearable>
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入手机号搜索"
          v-model="KpiUserInfo.mobile"
          style="width:200px"
        ></el-input>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入姓名搜索"
          v-model="KpiUserInfo.name"
          style="width:200px"
        ></el-input>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入用户ID"
          v-model="KpiUserInfo.userId"
          style="width:200px"
        ></el-input>
        <el-button type="primary" class="ml10" icon="el-icon-search" @click="getKpiUserList">搜索</el-button>
        <el-button type="primary" class="ml10" @click="exportData">导出表格</el-button>
      </div>
      <el-table class="secondsKill_table" :data="KpiUserList" border stripe style="width: 100%">
        <el-table-column prop="userId" label="用户ID"></el-table-column>
        <el-table-column prop="name" label="姓名"></el-table-column>
        <el-table-column prop="mobile" label="手机号码"></el-table-column>
        <el-table-column prop="brandName" label="所属品牌"></el-table-column>
        <el-table-column prop="shopName" label="所属门店"></el-table-column>
        <el-table-column prop="totalBeanNums" label="奖励豆总数"></el-table-column>
        <el-table-column prop="estimateIncome" label="预计收入"></el-table-column>
        <el-table-column label="完成状态">
          <template slot-scope="scope">
            <el-tag type="primary" v-if="scope.row.completionStatus=='0'">未完成</el-tag>
            <el-tag type="warning" v-if="scope.row.completionStatus=='1'">已完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="realIncome" label="实际收入"></el-table-column>
        <el-table-column prop="address" label="操作" fixed="right" width="240">
          <template slot-scope="scope">
            <el-tag type="primary" @click="ToKpiUserDetail(scope.row.id)">查看详情</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="tab-page">
        <div></div>
        <el-pagination
          background
          @current-change="handleCurrentChange2"
          :current-page="KpiUserInfo.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="KpiUserTotal"
        ></el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDataTotal=false">取 消</el-button>
        <el-button type="primary" @click="dialogDataTotal=false">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 数据统计查看详情 -->
    <el-dialog :visible.sync="dialogDataDetail" center width="1600px" class="detailsGuide_drawer">
      <p class="view_title">基础信息</p>
      <el-card class="box-card">
        <div class="basic_info_wrap">
          <div class="basic_info_item">
            <p class="name">用户ID:</p>
            <p class="data">{{KpiUserDetail.userId}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">姓名:</p>
            <p class="data">{{KpiUserDetail.name}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">手机号码:</p>
            <p class="data">{{KpiUserDetail.mobile}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">所属品牌:</p>
            <p class="data">{{KpiUserDetail.brandName}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">所属门店:</p>
            <p class="data">{{KpiUserDetail.shopName}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">奖励豆总数:</p>
            <p class="data">{{KpiUserDetail.totalBeanNums||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">预计收入:</p>
            <p class="data">{{KpiUserDetail.estimateIncome||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">完成状态:</p>
            <p class="data" v-if="KpiUserDetail.completionStatus=='0'">未完成</p>
            <p class="data" v-if="KpiUserDetail.completionStatus=='1'">已完成</p>
          </div>
          <div class="basic_info_item">
            <p class="name">实际收入:</p>
            <p class="data">{{KpiUserDetail.realIncome||0}}</p>
          </div>
        </div>
      </el-card>
      <p class="view_title">奖励豆明细</p>
      <el-card class="box-card">
        <div class="basic_info_wrap">
          <div class="basic_info_item">
            <p class="name">浏览客户:</p>
            <p class="data">{{KpiUserDetail.view||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">裂变客户:</p>
            <p class="data">{{KpiUserDetail.fission||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">报名客户:</p>
            <p class="data">{{KpiUserDetail.enrollment||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">售券客户:</p>
            <p class="data">{{KpiUserDetail.card||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">进直播间客户:</p>
            <p class="data">{{KpiUserDetail.live_view||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播下订:</p>
            <p class="data">{{KpiUserDetail.live_order_cus||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播自签单:</p>
            <p class="data">{{KpiUserDetail.live_self_order||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播贡献单:</p>
            <p class="data">{{KpiUserDetail.live_other_order||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播自签转单:</p>
            <p class="data">{{KpiUserDetail.live_self_order_change||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播贡献转单:</p>
            <p class="data">{{KpiUserDetail.live_other_order_change||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">过程自签单:</p>
            <p class="data">{{KpiUserDetail.process_self_order||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">过程贡献单:</p>
            <p class="data">{{KpiUserDetail.process_devote_order||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">秒杀客户:</p>
            <p class="data">{{KpiUserDetail.seckill_user||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">集赞客户:</p>
            <p class="data">{{KpiUserDetail.thumb||0}}</p>
          </div>
          <div class="basic_info_item">
            <p class="name">直播预约客户:</p>
            <p class="data">{{KpiUserDetail.appointment||0}}</p>
          </div>
        </div>
      </el-card>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDataDetail=false">取 消</el-button>
        <el-button type="primary" @click="dialogDataDetail=false">确 定</el-button>
      </div>
    </el-dialog>

    <div>
      <!-- 主页面 -->
      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="AddTask">添加阶段任务</el-button>
      <el-table class="secondsKill_table" :data="StepList" border stripe style="width: 100%">
        <el-table-column prop="name" label="阶段任务名称"></el-table-column>
        <el-table-column prop="startTime" label="开始时间"></el-table-column>
        <el-table-column prop="finishTime" label="结束时间"></el-table-column>
        <el-table-column prop="staffBonusPool" label="该阶段奖励金额（元）"></el-table-column>
        <el-table-column prop="totalBonusPool" label="奖励总金额（元）"></el-table-column>
        <el-table-column
          prop="totalBean"
          label="奖励豆总数"
          :render-header="(h)=>renderHeader(h,'奖励总豆数','该阶段任务，导购员需获得奖励豆总数')"
        ></el-table-column>
        <el-table-column
          prop="fictitiousBean"
          label="虚拟豆值"
          :render-header="(h)=>renderHeader(h,'虚拟豆值','该阶段奖励金额 / 奖励豆总数')"
        ></el-table-column>
        <el-table-column
          prop="actualTotalBean"
          label="实际奖励豆数"
          :render-header="(h)=>renderHeader(h,'实际奖励豆数','奖励豆总数 - 任务未完成导购员的奖励豆总数')"
        ></el-table-column>
        <el-table-column
          prop="actualBean"
          label="实际豆值"
          :render-header="(h)=>renderHeader(h,'实际豆值','该阶段奖励金额 / 实际奖励豆值')"
        ></el-table-column>
        <el-table-column prop="actualTotalBean" label="实际支出">
          <template slot-scope="scope">
            {{Number(scope.row.actualBean).mul(Number(scope.row.actualTotalBean))}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="300">
          <template slot-scope="scope">
            <el-tag class="m10 cp" @click="ToStaffTask(scope.row.id)">导购员任务指标</el-tag>
            <el-tag class="m10 cp" @click="ToBrandTask(scope.row.id)">品牌任务指标</el-tag>
            <el-tag class="m10 cp" @click="ToKpiUserList(scope.row.id)">数据统计</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="tab-page">
        <div></div>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="StepInfo.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="StepTotal"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
export default {
  name: "StageTask", //
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      nodata: "",
      pageIndex: 1,
      input: "",
      nobool: false,
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      addTaskDrawer: false,
      dialogWorkerTask: false,
      dialogDataTotal: false,
      dialogBannerTask: false,
      dialogDataDetail: false,
      branchViewWidth: "", // 窗体大小限制
      addBranchStoreForm: {
        name: "",
        poster: "",
      },
      branchAddRules: {
        name: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        address: [
          { required: true, message: "请输入门店地址", trigger: "blur" },
        ],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
      addLegionTableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      typeOptions: [
        { value: 1, label: "已完成" },
        { value: 0, label: "未完成" },
      ],

      // 阶段任务用参数
      StepList: [],
      StepTotal: 0,
      KpiUserList: [],
      KpiUserTotal: 0,
      StepInfo: {
        actId: 0,
        page: 1,
        size: 10,
      },
      KpiUserInfo: {
        actId: this.activityID,
        completionStatus: "",
        mobile: "",
        name: "",
        stepId: 0,
        userId: "",
        page: 1,
        size: 10,
      },
      editStepForm: {
        actId: this.activityID,
        startTime: "",
        finishTime: "",
        name: "",
        staffBonusPool: "",
        timeRange: [],
      },
      totalBonusPool: 0,
      availableBonusPool: 0,
      staffDetail: {
        actId: this.activityID,
        cardNum: 0,
        enrollmentNum: 0,
        fissionNum: 0,
        liveOrderCusNum: 0,
        liveOtherOrderChangeNum: 0,
        liveOtherOrderNum: 0,
        liveSelfOrderChangeNum: 0,
        liveSelfOrderNum: 0,
        liveViewNum: 0,
        processDevoteOrderNum: 0,
        processSelfOrderNum: 0,
        seckillUserNum: 0,
        stepId: 0,
        thumbNum: 0,
        viewNum: 0,
        appointmentNum: 0,
      },
      brandDetail: {
        actId: this.activityID,
        cardNum: 0,
        cardPenalty: 0,
        enrollmentNum: 0,
        enrollmentPenalty: 0,
        fissionNum: 0,
        fissionPenalty: 0,
        liveOrderCusNum: 0,
        liveOrderCusPenalty: 0,
        liveOtherOrderChangeNum: 0,
        liveOtherOrderChangePenalty: 0,
        liveOtherOrderNum: 0,
        liveOtherOrderPenalty: 0,
        liveSelfOrderChangeNum: 0,
        liveSelfOrderChangePenalty: 0,
        liveSelfOrderNum: 0,
        liveSelfOrderPenalty: 0,
        liveViewNum: 0,
        liveViewPenalty: 0,
        processDevoteOrderNum: 0,
        processDevoteOrderPenalty: 0,
        processSelfOrderNum: 0,
        processSelfOrderPenalty: 0,
        seckillUserNum: 0,
        seckillUserPenalty: 0,
        stepId: 0,
        thumbNum: 0,
        thumbPenalty: 0,
        viewNum: 0,
        viewPenalty: 0,
        appointmentNum: 0,
        appointmentPenalty: 0
      },
      // 当前编辑的阶段任务Id
      stepId: 0,
      nowkpiUserId: 0,
      KpiUserDetail: {
        userId: 0,
        name: "",
        mobile: "",
        brandName: "",
        shopName: "",
        completionStatus: "",
        totalBeanNums: 0,
        estimateIncome: 0,
        realIncome: 0,
        view: 0,
        fission: 0,
        enrollment: 0,
        card: 0,
        live_view: 0,
        live_order_cus: 0,
        live_self_order: 0,
        live_other_order: 0,
        live_self_order_change: 0,
        live_other_order_change: 0,
        process_self_order: 0,
        process_devote_order: 0,
        seckill_user: 0,
        thumb: 0,
        appointment: 0,
      },
      click: false,
      rules: {
        viewNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        viewPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        fissionNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        fissionPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        enrollmentNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        enrollmentPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        cardNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        cardPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveViewNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveViewPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveOrderCusNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveOrderCusPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveSelfOrderNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveSelfOrderPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveOtherOrderNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveOtherOrderPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveSelfOrderChangeNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveSelfOrderChangePenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveOtherOrderChangeNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        liveOtherOrderChangePenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        processSelfOrderNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        processSelfOrderPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        processDevoteOrderNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        processDevoteOrderPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        seckillUserNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        seckillUserPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        thumbNum: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
        thumbPenalty: [
          {
            pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
            message: "请输入大于0的正数",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    renderHeader(h, name, text) {
      return (
        <div>
          <span>{name}</span>
          <el-popover placement="top" width="200" trigger="click">
            <div class="scanDialog">{text}</div>
            <i class="el-icon-question" slot="reference"></i>
          </el-popover>
        </div>
      );
    },
    // 表格 页码请求
    handleCurrentChange(val) {
      this.StepInfo.page = val;
      this.getStepList();
    },
    // 数据统计页码请求
    handleCurrentChange2(val) {
      this.KpiUserInfo.page = val;
      this.getKpiUserList();
    },
    // 获取阶段任务列表
    async getStepList() {
      try {
        this.StepInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await GuideAjax.getStepList(this.StepInfo);
        this.StepList = list;
        this.StepTotal = total;
        console.log(list);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 获取阶段任务数据统计（搜索）
    async getKpiUserList() {
      try {
        this.KpiUserInfo.actId = this.activityID;
        this.KpiUserInfo.stepId = this.stepId;
        const {
          data: { list, total },
        } = await GuideAjax.getKpiUserList(this.KpiUserInfo);
        this.KpiUserList = list;
        this.KpiUserTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 获取阶段任务数据统计
    async ToKpiUserList(id) {
      try {
        this.KpiUserInfo.actId = this.activityID;
        this.KpiUserInfo.stepId = id;
        const {
          data: { list, total },
        } = await GuideAjax.getKpiUserList(this.KpiUserInfo);
        this.KpiUserList = list;
        this.KpiUserTotal = total;
        this.stepId = id;
        this.dialogDataTotal = true;
        console.log(list);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // KPI阶段任务查询奖励总金额和可用金额
    async getQueryActAcountByActId() {
      try {
        this.StepInfo.actId = this.activityID;
        const data = await GuideAjax.getQueryActAcountByActId(this.activityID);
        this.totalBonusPool = data.data.totalBonusPool;
        this.availableBonusPool = data.data.availableBonusPool;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 字符串判空
    isNullOrEmpty(str) {
      if (str === "" || str == null) {
        return true; // 返回正确的处理结果
      }
      return false; // 分会错误的处理结果，终止处理
    },
    // 确认新建阶段任务
    async submitInfo() {
      try {
        if (this.click) {
          return;
        }
        this.click = true;
        let act = JSON.parse(JSON.stringify(this.editStepForm));
        if (this.isNullOrEmpty(act.name)) {
          this.$message.warning("阶段任务名不能为空");
          this.click = false;
          return;
        }
        if (act.timeRange && act.timeRange.length > 0) {
          act.startTime = act.timeRange[0];
          act.finishTime = act.timeRange[1];
          // delete act.timeRange;
        } else {
          this.$message.warning("请选择任务时间");
          this.click = false;
          return;
        }
        if (this.isNullOrEmpty(act.staffBonusPool)) {
          this.$message.warning("该阶段使用金额不能为空");
          this.click = false;
          return;
        }
        if (/^\d+(\.\d{1,2})?$/.test(act.staffBonusPool) == false) {
          this.$message.warning("请输入正确该阶段使用金额，最多两位小数");
          this.click = false;
          return;
        }
        if (Number(this.availableBonusPool) < Number(act.staffBonusPool)) {
          this.$message.warning("该阶段使用金额不可大于可用金额");
          this.click = false;
          return;
        }

        console.log(act);
        await GuideAjax.getSaveOrUpdateStep(act);
        this.$message.success("新建成功");
        await this.getStepList();
        this.editStepForm = {
          actId: this.activityID,
          startTime: "",
          finishTime: "",
          name: "",
          staffBonusPool: "",
          timeRange: [],
        };
        this.addTaskDrawer = false;
      } catch (error) {
        if (error == "阶段任务时间重叠") {
          this.$message.warning("添加失败，请确保各阶段任务时间不重叠");
        } else {
          this.$message.warning(error);
        }
        this.click = false;
      }
    },
    // 查看导购员任务指标
    async ToStaffTask(id) {
      try {
        const data = await GuideAjax.getStaffStepByStepId(id);
        if (data.data) {
          this.staffDetail = data.data;
        } else {
          this.staffDetail = {
            actId: this.activityID,
            cardNum: 0,
            enrollmentNum: 0,
            fissionNum: 0,
            liveOrderCusNum: 0,
            liveOtherOrderChangeNum: 0,
            liveOtherOrderNum: 0,
            liveSelfOrderChangeNum: 0,
            liveSelfOrderNum: 0,
            liveViewNum: 0,
            processDevoteOrderNum: 0,
            processSelfOrderNum: 0,
            seckillUserNum: 0,
            stepId: 0,
            thumbNum: 0,
            viewNum: 0,
            appointmentNum: 0,
          };
        }
        this.stepId = id;
        this.dialogWorkerTask = true;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 新建或编辑阶段任务导购员任务指标
    async SaveOrUpdateStaffStep() {
      if (this.staffDetail.stepId == 0) {
        this.staffDetail.stepId = this.stepId;
      }
      await GuideAjax.SaveOrUpdateStaffStep(this.staffDetail);
      this.$message.success("保存成功");
      await this.getStepList();
      this.dialogWorkerTask = false;
    },
    // 查看品牌任务指标
    async ToBrandTask(id) {
      try {
        const data = await GuideAjax.getBrandStepByStepId(id);
        if (data.data) {
          this.brandDetail = data.data;
        } else {
          this.brandDetail = {
            actId: this.activityID,
            cardNum: 0,
            cardPenalty: 0,
            enrollmentNum: 0,
            enrollmentPenalty: 0,
            fissionNum: 0,
            fissionPenalty: 0,
            liveOrderCusNum: 0,
            liveOrderCusPenalty: 0,
            liveOtherOrderChangeNum: 0,
            liveOtherOrderChangePenalty: 0,
            liveOtherOrderNum: 0,
            liveOtherOrderPenalty: 0,
            liveSelfOrderChangeNum: 0,
            liveSelfOrderChangePenalty: 0,
            liveSelfOrderNum: 0,
            liveSelfOrderPenalty: 0,
            liveViewNum: 0,
            liveViewPenalty: 0,
            processDevoteOrderNum: 0,
            processDevoteOrderPenalty: 0,
            processSelfOrderNum: 0,
            processSelfOrderPenalty: 0,
            seckillUserNum: 0,
            seckillUserPenalty: 0,
            stepId: 0,
            thumbNum: 0,
            thumbPenalty: 0,
            viewNum: 0,
            viewPenalty: 0,
            appointmentNum: 0,
            appointmentPenalty: 0
          };
        }
        this.stepId = id;
        this.dialogBannerTask = true;
      } catch (error) {
        this.$message.error(error);
      }
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.SaveOrUpdateBrandStep();
        }
      });
    },
    // 新建或编辑阶段任务导购员任务指标
    async SaveOrUpdateBrandStep() {
      try {
        if (this.brandDetail.stepId == 0) {
          this.brandDetail.stepId = this.stepId;
        }
        // 对数据进行验证
        let res = await GuideAjax.SaveOrUpdateBrandStep(this.brandDetail);
        console.log(res);
        if (res.data && res.code == 0) {
          this.$message.success("保存成功");
          await this.getStepList();
          this.dialogBannerTask = false;
        } else {
          this.$message.warning(res.msg);
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出阶段任务数据统计表格
    async exportData() {
      try {
        const data = await GuideAjax.getExportKpiUserList(this.KpiUserInfo);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "阶段任务数据统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 获取阶段任务数据统计中导购员信息详情
    async ToKpiUserDetail(id) {
      try {
        this.KpiUserInfo.actId = this.activityID;
        this.KpiUserInfo.stepId = id;
        const data = await GuideAjax.getKpiUserListDetail({
          actId: this.activityID,
          id: id,
          stepId: this.stepId,
          // userId: 0
        });
        console.log(data.data);
        this.KpiUserDetail = data.data;

        //过滤kpi导购员下奖励豆订单列表
        if (this.KpiUserDetail.beanRecordList.length > 0) {
          this.KpiUserDetail.beanRecordList.forEach((item) => {
            var key = item.type;
            this.$set(this.KpiUserDetail, key, 0);
          });
          this.KpiUserDetail.beanRecordList.forEach((item) => {
            var key = item.type;
            var num = Number(this.KpiUserDetail[key]).add(item.amount);
            console.log(key);
            console.log(this.KpiUserDetail[key]);
            console.log(num);
            this.$set(this.KpiUserDetail, key, num);
            // this.KpiUserDetail[key]=item.amount
          });
        }

        // 获取当前详情的导购员id
        this.nowkpiUserId = id;
        this.dialogDataDetail = true;
      } catch (error) {
        this.$message.error(error);
      }
    },
    AddTask() {
      if (this.StepTotal>=30) {
        this.$message.warning("最多只可创建30个任务哦~");
        return;
      }
      this.click = false;
      this.addTaskDrawer = true;
      this.getQueryActAcountByActId();
    },
  },
  created() {
    this.getStepList();
  },
  mounted() {
    this.$nextTick(() => {
      $(".content").width() > 1280
        ? (this.branchViewWidth = "1280".toString())
        : (this.branchViewWidth = "80%");
    });
  },
};
</script>

<style lang="scss">
.StageTask_wrapper {
  .view_title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
    &:before {
      display: inline-block;
      width: 3px;
      height: 23px;
      vertical-align: sub;
      background-color: #419eff;
      line-height: 2;
      margin-right: 20px;
      content: "";
    }
  }
  // 添加任务
  .addTask_drawer {
    .el-drawer__body {
      padding-right: 20px;
      .el-form-item__content {
        display: flex;
        align-items: center;
        .el-upload--text {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 178px;
          height: 178px;
        }
        .form_tips {
          color: #888;
          font-size: 12px;
        }
      }
    }
  }
  .box_card {
    margin-bottom: 20px;
    .el-card__body {
      .kpi_info_wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .kpi_info_item {
          width: 160px;
          line-height: 40px;
          color: #409eff;
          border: 1px solid #d9ecff;
          background-color: #ecf5ff;
          padding: 0 20px 10px;
          margin: 0 10px 10px 0;
          cursor: pointer;
          border-radius: 4px;
          &:hover {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          }
        }
        .kpi_info_item .el-input-number--medium {
          width: 160px;
        }
      }
    }
  }
  .datatotal_dialog {
    .select_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      div {
        height: 40px;
        line-height: 40px;
        margin: 0 10px;
        .el-select {
          width: 150px !important;
        }
        .el-input {
          width: 200px;
        }
      }
    }
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .secondsKill_table {
    margin-top: 20px;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
  .detailsGuide_drawer {
    .view_title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
      &:before {
        display: inline-block;
        width: 3px;
        height: 23px;
        vertical-align: sub;
        background-color: #419eff;
        line-height: 2;
        margin-right: 20px;
        content: "";
      }
    }
    .el-card {
      margin-bottom: 30px;
      .basic_info_wrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .basic_info_item {
          display: flex;
          flex-direction: row;
          height: 40px;
          line-height: 40px;
          color: #409eff;
          border: 1px solid #d9ecff;
          background-color: #ecf5ff;
          margin: 10px;
          padding: 0 20px;
          cursor: pointer;
          border-radius: 4px;
          &:hover {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          }
          .name {
            padding-right: 20px;
          }
        }
      }
    }
  }
}
</style>
