var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "StageTask_wrapper" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.addTaskDrawer,
            "show-close": false,
            center: "",
            width: "700px"
          },
          on: {
            "update:visible": function($event) {
              _vm.addTaskDrawer = $event
            }
          }
        },
        [
          _c(
            "el-card",
            { staticClass: "box_card" },
            [
              _c(
                "div",
                {
                  staticClass: "clearfix",
                  attrs: { slot: "header" },
                  slot: "header"
                },
                [_c("span", [_vm._v("添加阶段任务")])]
              ),
              _c(
                "el-form",
                { attrs: { model: _vm.editStepForm, "label-width": "170px" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "阶段任务：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入阶段任务名称",
                          maxlength: "20",
                          "show-word-limit": ""
                        },
                        model: {
                          value: _vm.editStepForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.editStepForm, "name", $$v)
                          },
                          expression: "editStepForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务时间：", required: "" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期"
                        },
                        model: {
                          value: _vm.editStepForm.timeRange,
                          callback: function($$v) {
                            _vm.$set(_vm.editStepForm, "timeRange", $$v)
                          },
                          expression: "editStepForm.timeRange"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "奖励总金额：" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.totalBonusPool))])
                  ]),
                  _c("el-form-item", { attrs: { label: "可用金额:" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.availableBonusPool))])
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "该阶段使用金额：", required: "" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入金额", maxlength: "8" },
                        model: {
                          value: _vm.editStepForm.staffBonusPool,
                          callback: function($$v) {
                            _vm.$set(_vm.editStepForm, "staffBonusPool", $$v)
                          },
                          expression: "editStepForm.staffBonusPool"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.addTaskDrawer = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitInfo } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogWorkerTask,
            "show-close": false,
            center: "",
            width: "1600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogWorkerTask = $event
            }
          }
        },
        [
          _c("el-card", { staticClass: "box_card" }, [
            _c(
              "div",
              {
                staticClass: "clearfix",
                attrs: { slot: "header" },
                slot: "header"
              },
              [_c("span", [_vm._v("导购员任务设置")])]
            ),
            _c("div", { staticClass: "kpi_info_wrap" }, [
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("浏览")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.viewNum,
                      callback: function($$v) {
                        _vm.$set(_vm.staffDetail, "viewNum", _vm._n($$v))
                      },
                      expression: "staffDetail.viewNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("裂变")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.fissionNum,
                      callback: function($$v) {
                        _vm.$set(_vm.staffDetail, "fissionNum", _vm._n($$v))
                      },
                      expression: "staffDetail.fissionNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("报名")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.enrollmentNum,
                      callback: function($$v) {
                        _vm.$set(_vm.staffDetail, "enrollmentNum", _vm._n($$v))
                      },
                      expression: "staffDetail.enrollmentNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("售券")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.cardNum,
                      callback: function($$v) {
                        _vm.$set(_vm.staffDetail, "cardNum", _vm._n($$v))
                      },
                      expression: "staffDetail.cardNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("进入直播间")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.liveViewNum,
                      callback: function($$v) {
                        _vm.$set(_vm.staffDetail, "liveViewNum", _vm._n($$v))
                      },
                      expression: "staffDetail.liveViewNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("直播下订")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.liveOrderCusNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffDetail,
                          "liveOrderCusNum",
                          _vm._n($$v)
                        )
                      },
                      expression: "staffDetail.liveOrderCusNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("直播自签单")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.liveSelfOrderNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffDetail,
                          "liveSelfOrderNum",
                          _vm._n($$v)
                        )
                      },
                      expression: "staffDetail.liveSelfOrderNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("直播贡献单")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.liveOtherOrderNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffDetail,
                          "liveOtherOrderNum",
                          _vm._n($$v)
                        )
                      },
                      expression: "staffDetail.liveOtherOrderNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("直播自签转单")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.liveSelfOrderChangeNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffDetail,
                          "liveSelfOrderChangeNum",
                          _vm._n($$v)
                        )
                      },
                      expression: "staffDetail.liveSelfOrderChangeNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("直播贡献转单")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.liveOtherOrderChangeNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffDetail,
                          "liveOtherOrderChangeNum",
                          _vm._n($$v)
                        )
                      },
                      expression: "staffDetail.liveOtherOrderChangeNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("过程自签单")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.processSelfOrderNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffDetail,
                          "processSelfOrderNum",
                          _vm._n($$v)
                        )
                      },
                      expression: "staffDetail.processSelfOrderNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("过程贡献单")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.processDevoteOrderNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.staffDetail,
                          "processDevoteOrderNum",
                          _vm._n($$v)
                        )
                      },
                      expression: "staffDetail.processDevoteOrderNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("秒杀")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.seckillUserNum,
                      callback: function($$v) {
                        _vm.$set(_vm.staffDetail, "seckillUserNum", _vm._n($$v))
                      },
                      expression: "staffDetail.seckillUserNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("集赞")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.thumbNum,
                      callback: function($$v) {
                        _vm.$set(_vm.staffDetail, "thumbNum", _vm._n($$v))
                      },
                      expression: "staffDetail.thumbNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "kpi_info_item" },
                [
                  _c("p", { staticClass: "name" }, [_vm._v("直播预约")]),
                  _c("el-input", {
                    attrs: { maxlength: "8", placeholder: "请输入内容" },
                    model: {
                      value: _vm.staffDetail.appointmentNum,
                      callback: function($$v) {
                        _vm.$set(_vm.staffDetail, "appointmentNum", _vm._n($$v))
                      },
                      expression: "staffDetail.appointmentNum"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogWorkerTask = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.SaveOrUpdateStaffStep }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogBannerTask,
            "show-close": false,
            center: "",
            width: "1600px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogBannerTask = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: { model: _vm.brandDetail, rules: _vm.rules }
            },
            [
              _c("el-card", { staticClass: "box_card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("品牌任务指标及惩罚金")])]
                ),
                _c("div", { staticClass: "kpi_info_wrap" }, [
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("浏览")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "viewNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.viewNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "viewNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.viewNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "viewPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.viewPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "viewPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.viewPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("裂变")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "fissionNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.fissionNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "fissionNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.fissionNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "fissionPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.fissionPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "fissionPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.fissionPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("报名")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "enrollmentNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.enrollmentNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "enrollmentNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.enrollmentNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "enrollmentPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.enrollmentPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "enrollmentPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.enrollmentPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("售券")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "cardNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.cardNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "cardNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.cardNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "cardPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.cardPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "cardPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.cardPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("进入直播间")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveViewNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveViewNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveViewNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveViewNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveViewPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveViewPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveViewPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveViewPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("直播下订")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveOrderCusNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveOrderCusNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveOrderCusNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveOrderCusNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveOrderCusPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveOrderCusPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveOrderCusPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveOrderCusPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("直播自签单")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveSelfOrderNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveSelfOrderNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveSelfOrderNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveSelfOrderNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveSelfOrderPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveSelfOrderPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveSelfOrderPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveSelfOrderPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("直播贡献单")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveOtherOrderNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveOtherOrderNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveOtherOrderNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveOtherOrderNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveOtherOrderPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveOtherOrderPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveOtherOrderPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveOtherOrderPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v("直播自签转单")
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveSelfOrderChangeNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveSelfOrderChangeNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveSelfOrderChangeNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveSelfOrderChangeNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveSelfOrderChangePenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveSelfOrderChangePenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveSelfOrderChangePenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "brandDetail.liveSelfOrderChangePenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [
                        _vm._v("直播贡献转单")
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveOtherOrderChangeNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.liveOtherOrderChangeNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveOtherOrderChangeNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.liveOtherOrderChangeNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "liveOtherOrderChangePenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value:
                                _vm.brandDetail.liveOtherOrderChangePenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "liveOtherOrderChangePenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "brandDetail.liveOtherOrderChangePenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("过程自签单")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "processSelfOrderNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.processSelfOrderNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "processSelfOrderNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.processSelfOrderNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "processSelfOrderPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.processSelfOrderPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "processSelfOrderPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.processSelfOrderPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("过程贡献单")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "processDevoteOrderNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.processDevoteOrderNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "processDevoteOrderNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.processDevoteOrderNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "processDevoteOrderPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.processDevoteOrderPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "processDevoteOrderPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "brandDetail.processDevoteOrderPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("秒杀")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "seckillUserNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.seckillUserNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "seckillUserNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.seckillUserNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "seckillUserPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.seckillUserPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "seckillUserPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.seckillUserPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("集赞")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "thumbNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.thumbNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "thumbNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.thumbNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "thumbPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.thumbPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "thumbPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.thumbPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "kpi_info_item" },
                    [
                      _c("p", { staticClass: "name" }, [_vm._v("直播预约")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "appointmentNum" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.appointmentNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "appointmentNum",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.appointmentNum"
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "name" }, [_vm._v("惩罚金")]),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "thumbPenalty" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              placeholder: "请输入内容"
                            },
                            model: {
                              value: _vm.brandDetail.appointmentPenalty,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brandDetail,
                                  "appointmentPenalty",
                                  _vm._n($$v)
                                )
                              },
                              expression: "brandDetail.appointmentPenalty"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "div",
                { staticStyle: { margin: "0 auto" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogBannerTask = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("确 定")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "datatotal_dialog",
          attrs: { visible: _vm.dialogDataTotal, center: "", width: "1600px" },
          on: {
            "update:visible": function($event) {
              _vm.dialogDataTotal = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("数据统计")]),
          _c(
            "div",
            { staticClass: "select_wrap" },
            [
              _c(
                "el-select",
                {
                  staticClass: "mr10",
                  attrs: { placeholder: "完成状态", clearable: "" },
                  model: {
                    value: _vm.KpiUserInfo.completionStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.KpiUserInfo, "completionStatus", $$v)
                    },
                    expression: "KpiUserInfo.completionStatus"
                  }
                },
                _vm._l(_vm.typeOptions, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              ),
              _c("el-input", {
                staticClass: "ml10",
                staticStyle: { width: "200px" },
                attrs: { clearable: true, placeholder: "请输入手机号搜索" },
                model: {
                  value: _vm.KpiUserInfo.mobile,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiUserInfo, "mobile", $$v)
                  },
                  expression: "KpiUserInfo.mobile"
                }
              }),
              _c("el-input", {
                staticClass: "ml10",
                staticStyle: { width: "200px" },
                attrs: { clearable: true, placeholder: "请输入姓名搜索" },
                model: {
                  value: _vm.KpiUserInfo.name,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiUserInfo, "name", $$v)
                  },
                  expression: "KpiUserInfo.name"
                }
              }),
              _c("el-input", {
                staticClass: "ml10",
                staticStyle: { width: "200px" },
                attrs: { clearable: true, placeholder: "请输入用户ID" },
                model: {
                  value: _vm.KpiUserInfo.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.KpiUserInfo, "userId", $$v)
                  },
                  expression: "KpiUserInfo.userId"
                }
              }),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.getKpiUserList }
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "primary" },
                  on: { click: _vm.exportData }
                },
                [_vm._v("导出表格")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              staticClass: "secondsKill_table",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.KpiUserList, border: "", stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "userId", label: "用户ID" }
              }),
              _c("el-table-column", { attrs: { prop: "name", label: "姓名" } }),
              _c("el-table-column", {
                attrs: { prop: "mobile", label: "手机号码" }
              }),
              _c("el-table-column", {
                attrs: { prop: "brandName", label: "所属品牌" }
              }),
              _c("el-table-column", {
                attrs: { prop: "shopName", label: "所属门店" }
              }),
              _c("el-table-column", {
                attrs: { prop: "totalBeanNums", label: "奖励豆总数" }
              }),
              _c("el-table-column", {
                attrs: { prop: "estimateIncome", label: "预计收入" }
              }),
              _c("el-table-column", {
                attrs: { label: "完成状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.completionStatus == "0"
                          ? _c("el-tag", { attrs: { type: "primary" } }, [
                              _vm._v("未完成")
                            ])
                          : _vm._e(),
                        scope.row.completionStatus == "1"
                          ? _c("el-tag", { attrs: { type: "warning" } }, [
                              _vm._v("已完成")
                            ])
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "realIncome", label: "实际收入" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "操作",
                  fixed: "right",
                  width: "240"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.ToKpiUserDetail(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("查看详情")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-page" },
            [
              _c("div"),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.KpiUserInfo.page,
                  "page-size": 10,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.KpiUserTotal
                },
                on: { "current-change": _vm.handleCurrentChange2 }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogDataTotal = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogDataTotal = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "detailsGuide_drawer",
          attrs: { visible: _vm.dialogDataDetail, center: "", width: "1600px" },
          on: {
            "update:visible": function($event) {
              _vm.dialogDataDetail = $event
            }
          }
        },
        [
          _c("p", { staticClass: "view_title" }, [_vm._v("基础信息")]),
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", { staticClass: "basic_info_wrap" }, [
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("用户ID:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.userId))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("姓名:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.name))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("手机号码:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.mobile))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("所属品牌:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.brandName))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("所属门店:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.shopName))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("奖励豆总数:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.totalBeanNums || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("预计收入:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.estimateIncome || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("完成状态:")]),
                _vm.KpiUserDetail.completionStatus == "0"
                  ? _c("p", { staticClass: "data" }, [_vm._v("未完成")])
                  : _vm._e(),
                _vm.KpiUserDetail.completionStatus == "1"
                  ? _c("p", { staticClass: "data" }, [_vm._v("已完成")])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("实际收入:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.realIncome || 0))
                ])
              ])
            ])
          ]),
          _c("p", { staticClass: "view_title" }, [_vm._v("奖励豆明细")]),
          _c("el-card", { staticClass: "box-card" }, [
            _c("div", { staticClass: "basic_info_wrap" }, [
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("浏览客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.view || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("裂变客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.fission || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("报名客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.enrollment || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("售券客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.card || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("进直播间客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.live_view || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播下订:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.live_order_cus || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播自签单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.live_self_order || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播贡献单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.live_other_order || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播自签转单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.live_self_order_change || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播贡献转单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.live_other_order_change || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("过程自签单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.process_self_order || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("过程贡献单:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.process_devote_order || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("秒杀客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.seckill_user || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("集赞客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.thumb || 0))
                ])
              ]),
              _c("div", { staticClass: "basic_info_item" }, [
                _c("p", { staticClass: "name" }, [_vm._v("直播预约客户:")]),
                _c("p", { staticClass: "data" }, [
                  _vm._v(_vm._s(_vm.KpiUserDetail.appointment || 0))
                ])
              ])
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogDataDetail = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogDataDetail = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
              on: { click: _vm.AddTask }
            },
            [_vm._v("添加阶段任务")]
          ),
          _c(
            "el-table",
            {
              staticClass: "secondsKill_table",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.StepList, border: "", stripe: "" }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "阶段任务名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "startTime", label: "开始时间" }
              }),
              _c("el-table-column", {
                attrs: { prop: "finishTime", label: "结束时间" }
              }),
              _c("el-table-column", {
                attrs: { prop: "staffBonusPool", label: "该阶段奖励金额（元）" }
              }),
              _c("el-table-column", {
                attrs: { prop: "totalBonusPool", label: "奖励总金额（元）" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "totalBean",
                  label: "奖励豆总数",
                  "render-header": function(h) {
                    return _vm.renderHeader(
                      h,
                      "奖励总豆数",
                      "该阶段任务，导购员需获得奖励豆总数"
                    )
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fictitiousBean",
                  label: "虚拟豆值",
                  "render-header": function(h) {
                    return _vm.renderHeader(
                      h,
                      "虚拟豆值",
                      "该阶段奖励金额 / 奖励豆总数"
                    )
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "actualTotalBean",
                  label: "实际奖励豆数",
                  "render-header": function(h) {
                    return _vm.renderHeader(
                      h,
                      "实际奖励豆数",
                      "奖励豆总数 - 任务未完成导购员的奖励豆总数"
                    )
                  }
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "actualBean",
                  label: "实际豆值",
                  "render-header": function(h) {
                    return _vm.renderHeader(
                      h,
                      "实际豆值",
                      "该阶段奖励金额 / 实际奖励豆值"
                    )
                  }
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "actualTotalBean", label: "实际支出" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              Number(scope.row.actualBean).mul(
                                Number(scope.row.actualTotalBean)
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "300" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            staticClass: "m10 cp",
                            on: {
                              click: function($event) {
                                return _vm.ToStaffTask(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("导购员任务指标")]
                        ),
                        _c(
                          "el-tag",
                          {
                            staticClass: "m10 cp",
                            on: {
                              click: function($event) {
                                return _vm.ToBrandTask(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("品牌任务指标")]
                        ),
                        _c(
                          "el-tag",
                          {
                            staticClass: "m10 cp",
                            on: {
                              click: function($event) {
                                return _vm.ToKpiUserList(scope.row.id)
                              }
                            }
                          },
                          [_vm._v("数据统计")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tab-page" },
            [
              _c("div"),
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.StepInfo.page,
                  "page-size": 10,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.StepTotal
                },
                on: { "current-change": _vm.handleCurrentChange }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }